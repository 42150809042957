import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest, { fileRequest } from "../../helpers/createRequest";

import * as routines from "./routines";
function* UpdateWatcher({ payload }) {
  const { id, values, curType, onSuccess = () => { }, onError = () => { } } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.put, `laboratory/controls/${id}/`, { ...values });
    const lab_checks = yield select(state => state.lab_checks.all);
    const perms = yield select(state => state.auth.permissions);

    let newResults
    if (lab_checks.results && response.data.laboratory_status != curType) {
      newResults = lab_checks.results.filter(item => item.id !== response.data.id)
      yield put(routines.loadAll.success({ results: newResults, meta: lab_checks.meta }));
    }
    yield put(routines.update.success(response.data));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}
function* CreateWatcher({ payload }) {
  const { id } = payload
  const lab_checks = yield select(state => state.lab_checks.all);
  try {
    yield put(routines.create.request());

    const response = yield call(createRequest.get, `laboratory/controls/${id}/`);

    const { data } = response;

    yield put(routines.create.success({
      results: data
    }));
    yield put(routines.loadAll.success({ results: [...lab_checks.results, data], meta: lab_checks.meta }));
  } catch (error) {
    yield put(routines.create.failure());
  } finally {
    yield put(routines.create.fulfill());
  }
}

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/laboratory/controls/", {
      params: {
        page: payload.page,
        page_size: 20,
        ...payload
      }
    });

    const { links,
      page_size,
      count,
      total_pages, results } = response.data;

    yield put(routines.loadAll.success({
      results,
      meta: {
        links,
        page_size,
        count,
        total_pages
      }
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}


function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/laboratory/controls/${payload.id}/`);

    const content = response.data;

    yield put(routines.loadOne.success({ results: content }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
function* AcceptGivenIds({ payload }) {
  const { ids, onSuccess, accept } = payload;

  try {
    yield put(routines.acceptGivenIds.request());
    let response
    if (accept) {
      response = yield call(createRequest.post, `laboratory/controls/accept/`, { ids });
    } else {
      response = yield call(createRequest.post, `laboratory/controls/abort/`, { ids });
    }
    const lab_checks = yield select(state => state.lab_checks.all);
    const { data } = response
    let newResults = [...lab_checks.results]

    yield data.forEach(element => {
      newResults = [...newResults.filter(item => item.id !== element.id)]
    });
    yield put(routines.loadAll.success({ results: newResults, meta: lab_checks.meta }));
    yield put(routines.acceptGivenIds.success(response.data));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.acceptGivenIds.failure());
  } finally {
    yield put(routines.acceptGivenIds.fulfill());
  }
}
function* DeleteOneWatcher({ payload }) {

  const users = yield select(state => state.users.all);
  const { id, onSuccess = () => { }, onError = () => { } } = payload;

  try {
    yield call(createRequest.delete, `/auth/users/${id}`);


    let newUsers
    if (users.content) {
      newUsers = users.content.filter(user => user.id !== id)
    } else {
      newUsers = []
    }
    yield put(routines.loadAll.success({ content: newUsers, meta: users.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error.response);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}
function* CheckGivenIds({ payload }) {
  const { ids, onSuccess } = payload;

  try {
    yield put(routines.checkGivenIds.request());

    const response = yield call(createRequest.post, `laboratory/controls/check/`, { ids });
    const lab_checks = yield select(state => state.lab_checks.all);
    const { data } = response
    let newResults = [...lab_checks.results]

    yield data.forEach(element => {
      newResults = [...newResults.filter(item => item.id !== element.id)]
    });
    yield put(routines.loadAll.success({ results: newResults, meta: lab_checks.meta }));
    yield put(routines.checkGivenIds.success(response.data));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.checkGivenIds.failure());
  } finally {
    yield put(routines.checkGivenIds.fulfill());
  }
}
// It actually not created it, the lab controlled by an expert
function* ControlWithIdsWatcher({ payload }) {
  const { ids, onSuccess = () => { }, onError = () => { } } = payload;

  try {
    const response = yield call(createRequest.post, `laboratory/controls/check/`, { ids });
    const { data } = response
    const lab_checks = yield select(state => state.lab_checks.all);
    yield put(routines.controlGivenIds.request());
    let newResults = [...lab_checks.results]
    yield data.forEach(element => {
      newResults = [...newResults.filter(item => item.id !== element.id)]
    });
    yield call(console.log, newResults)
    yield put(routines.controlGivenIds.success(ids));
    yield put(routines.loadAll.success({ results: newResults, meta: lab_checks.meta }));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.controlGivenIds.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.controlGivenIds.fulfill());
  }
}
export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher),
    takeLatest(routines.acceptGivenIds, AcceptGivenIds),
    takeLatest(routines.checkGivenIds, CheckGivenIds),
    takeLatest(routines.controlGivenIds.TRIGGER, ControlWithIdsWatcher),
    takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
  ]);
}

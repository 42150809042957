import { SET_CURRENT, FETCH_ALL_FIELDS_TABLE_BEGIN, FETCH_ALL_FIELDS_TABLE_SUCCESS, FETCH_ALL_FIELDS_TABLE_ERROR, ADD_FIELD_TABLE_SUCCESS, ADDED_MAP_OF_FIELD } from '../constants'

const initialState = {
  fields: [],
  current: 1,
  count: 0,
  total: 0,
  loading: false,
  error: null
}

export function fieldsTable(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT:
      return {
        ...state,
        current: action.payload.number
      }
    case FETCH_ALL_FIELDS_TABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_ALL_FIELDS_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: action.payload.fields,
        count: action.payload.pagination.count,
        total: action.payload.pagination.total
      }
    case ADD_FIELD_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: [action.payload.field, ...state.fields],
      }
      case ADDED_MAP_OF_FIELD:
          return {
            ...state,
            fields: state.fields.map((item, index) => {
              return (action.payload.id === item.id) ? { ...item, is_polygon: !item.is_polygon } : { ...item}
            }),
          }
    case FETCH_ALL_FIELDS_TABLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fields: [],
        pagination: null
      }
    default: return state;
  }
}

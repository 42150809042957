import { FETCH_ALL_MEDICINES_TABLE_BEGIN, FETCH_ALL_MEDICINES_TABLE_SUCCESS, FETCH_ALL_MEDICINES_TABLE_ERROR} from '../constants'

const initialState = {
  medicines: [],
  count: 0,
  total: 0,
  loading: false,
  error: null
}

export function medicines(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_MEDICINES_TABLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case FETCH_ALL_MEDICINES_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        medicines: action.payload.medicines,
        count: action.payload.pagination.count,
        total: action.payload.pagination.total
      }
    case FETCH_ALL_MEDICINES_TABLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        medicines: [],
        pagination: null
      }
    default: return state;
  }
}

import { statisticsConstants } from '../constants'

const initialState = {
  statistics: null,
  loading: false,
  error: null
}

export function statistics(state = initialState, action) {
  switch (action.type) {
    case statisticsConstants.FETCH_ALL_STATISTICS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case statisticsConstants.FETCH_ALL_STATISTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        statistics: action.payload.data
      }
    case statisticsConstants.FETCH_ALL_STATISTICS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statistics: null,
      }
    default: return state;
  }
}

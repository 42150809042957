import { citiesConstants } from '../constants'

const initialState = {
  cities: [],
  districts: [],
  filteredDistricts: [],
  loading: false,
  error: null
}

export function cities(state = initialState, action) {
  switch (action.type) {
    case citiesConstants.FETCH_ALL_CITIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case citiesConstants.FETCH_ALL_CITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        filteredDistricts: [],
        cities: action.payload.cities,
        districts: action.payload.districts
      }
    case citiesConstants.FILTER_DISTRICTS:
      return {
        ...state,
        loading: false,
        filteredDistricts: state.districts.filter((item,index) => {
          return action.payload.city_id === item.id
        })
      }
    case citiesConstants.FETCH_ALL_CITIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        filteredDistricts: [],
        cities: [],
        districts: []
      }
    default: return state;
  }
}

export const pestConstants={
  FETCH_ALL_PESTS_BEGIN: 'FETCH_ALL_PESTS_BEGIN',
  FETCH_ALL_PESTS_SUCCESS:'FETCH_ALL_PESTS_SUCCESS',
  FETCH_ALL_PESTS_ERROR:'FETCH_ALL_PESTS_ERROR',
  TOGGLE_PEST:'TOGGLE_PEST',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  FETCH_ONE_PEST_BEGIN: 'FETCH_ONE_PEST_BEGIN',
  FETCH_ONE_PEST_SUCCESS: 'FETCH_ONE_PEST_SUCCESS'
};


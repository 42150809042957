export const FETCH_ALL_FIELDS_BEGIN= 'FETCH_ALL_FIELDS_BEGIN';
export const FETCH_ALL_FIELDS_SUCCESS='FETCH_ALL_FIELDS_SUCCESS';
export const FETCH_ALL_FIELDS_ERROR='FETCH_ALL_FIELDS_ERROR';
export const ADD_FIELD='ADD_FIELD';
export const ADD_FIELD_SUCCESS='ADD_FIELD_SUCCESS';
export const ONE_INFO_CLICKED='ONE_INFO_CLICKED';
export const ONE_INFO_CLICKED_SUCCESS='ONE_INFO_CLICKED_SUCCESS';
export const ONE_INFO_TABLE_SUCCESS='ONE_INFO_TABLE_SUCCESS';
export const ONE_INFO_TABLE_UPDATE='ONE_INFO_TABLE_UPDATE';
export const ONE_INFO_CLICKED_ERROR='ONE_INFO_CLICKED_ERROR';
export const ONE_INFO_CLOSED='ONE_INFO_CLOSED';
export const QUARANTINES_MODAL_TOGGLE='QUARANTINES_MODAL_TOGGLE';
export const ADD_CHECK_SUCCESS='ADD_CHECK_SUCCESS';
export const ADD_CHECK_BEGIN='ADD_CHECK_BEGIN';
export const QUARANTINE_OPEN_TOGGLE='QUARANTINE_OPEN_TOGGLE';
export const FILTER_FIELDS='FILTER_FIELDS';
export const RESET_FILTER='RESET_FILTER';
export const CHANGED_GOOGLE_FIELDS='CHANGED_GOOGLE_FIELDS';
export const FILTERED_GOOGLE_FIELDS='FILTERED_GOOGLE_FIELDS';

export const FETCH_ALL_GOOGLE_FIELDS_BEGIN= 'FETCH_ALL_GOOGLE_FIELDS_BEGIN';
export const FETCH_ALL_GOOGLE_FIELDS_SUCCESS='FETCH_ALL_GOOGLE_FIELDS_SUCCESS';
export const FETCH_ALL_GOOGLE_FIELDS_ERROR='FETCH_ALL_GOOGLE_FIELDS_ERROR';
export const FILTER_ALL_GOOGLE_FIELDS_BEGIN= 'FILTER_ALL_GOOGLE_FIELDS_BEGIN';
export const FILTER_ALL_GOOGLE_FIELDS_SUCCESS= 'FILTER_ALL_GOOGLE_FIELDS_SUCCESS';

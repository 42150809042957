import { createStore, applyMiddleware, compose } from "redux";

import reducers from "./reducers";
import sagas from "./sagas";
import middlewares, { sagaMiddleware } from "./middlewares";
import { composeWithDevTools } from 'redux-devtools-extension';
export default function configureStore(initialState) {
  const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25
  })) || compose;
  const store = createStore(reducers, initialState,composeWithDevTools(applyMiddleware(...middlewares)));
  sagaMiddleware.run(sagas);
  return store;
}
import { FETCH_ALL_GOOGLE_FIELDS_BEGIN, FETCH_ALL_GOOGLE_FIELDS_SUCCESS, FETCH_ALL_GOOGLE_FIELDS_ERROR, FILTER_ALL_GOOGLE_FIELDS_BEGIN, FILTER_ALL_GOOGLE_FIELDS_SUCCESS, FILTERED_GOOGLE_FIELDS } from '../constants'
const initialState = {
    fields: [],
    loading: false,
    error: null
}

export function googleFields(state = initialState, action) {
    switch (action.type) {
        case FETCH_ALL_GOOGLE_FIELDS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FETCH_ALL_GOOGLE_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                fields: [...action.payload.fields],
                error: null
            }
        case FETCH_ALL_GOOGLE_FIELDS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                fields: []
            }
        case FILTER_ALL_GOOGLE_FIELDS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            }

        case FILTER_ALL_GOOGLE_FIELDS_SUCCESS:
            return {
                ...state,
                loading: false,
                fields: action.payload.fields,
                error: null
            }
        case FILTERED_GOOGLE_FIELDS:
            return {
                ...state,
                loading: false,
                fields: state.fields.map((item, idx) => (
                    item.inn == action.payload.inn ? { ...item, visible: true } : { ...item, visible: false }
                )),
                error: null
            }
        default: return state;
    }
}


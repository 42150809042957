import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest from "../../helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/shop/orders", {
      params: {
        page: payload.page
      }
    });

    const { content,
      empty,
      first,
      last,
      number,
      numberOfElements,
      pageable,
      size,
      sort,
      totalElements,
      totalPages } = response.data;

    yield put(routines.loadAll.success({
      content,
      meta: {
        empty,
        first,
        last,
        number,
        numberOfElements,
        pageable,
        size,
        sort,
        totalElements,
        totalPages
      }
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* UpdateWatcher({ payload }) {
  const { user_id, values, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.put, `/auth/users/${user_id}`, { ...values });

    yield put(routines.update.success(response.data));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}
function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/auth/users/${payload.user_id}`);

    const content= response.data;

    yield put(routines.loadOne.success({ content }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
// function* CreateWatcher({ payload }) {
//   const { values, onSuccess = () => { }, onError = () => { } } = payload;
//   const users= yield select(state => state.users.all);
//   try {
//     yield put(routines.create.request());
//     const response = yield call(createRequest.post, "/auth/users", { ...values });
//     const newUsers=[response.data,...users.content]
//     yield put(routines.create.success(response.data));
//     yield put(routines.loadAll.success({ content: newUsers, meta: users.meta }));
//     yield call(onSuccess, response.data);
//   } catch (error) {
//     yield put(routines.create.failure());
//     yield call(onError, error);
//   } finally {
//     yield put(routines.create.fulfill());
//   }
// }
function* DeleteOneWatcher({ payload }) {

  const users= yield select(state => state.users.all);
  const { id, onSuccess = () => {}, onError = () => {} } = payload;

  try {
    yield call(createRequest.delete, `/auth/users/${id}`);


    let newUsers 
    if(users.content){
      newUsers=users.content.filter(user => user.id !=id)
    }else{
      newUsers=[]
    }
    yield put(routines.loadAll.success({ content: newUsers, meta: users.meta }));

    yield call(onSuccess);
  } catch (error) {
    yield put(routines.deleteOne.failure(error));
    yield call(onError, error.response);
  } finally {
    yield put(routines.deleteOne.fulfill());
  }
}
export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.deleteOne.TRIGGER, DeleteOneWatcher),
    // takeLatest(routines.create.TRIGGER, CreateWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
  ]);
}

export const API_URL = "https://api.assalomagro.uz";
export const BASE_URL = `${API_URL}/api/`;
export const SERVER_FILE_BASE_URL = `${API_URL}/api`;
export const AUTH_BASE_URL = `${API_URL}/`;
export const CURRENCY = "UZS";
export const CURRENCIES = {
    'usd': 'USD',
    'rub': 'RUB',
    'uzs': 'UZS'
}

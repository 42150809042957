import { ONE_INFO_TABLE_SUCCESS, ONE_INFO_CLICKED, ONE_INFO_CLICKED_SUCCESS, ONE_INFO_CLICKED_ERROR, ONE_INFO_CLOSED, QUARANTINES_MODAL_TOGGLE, ADD_CHECK_BEGIN, ADD_CHECK_SUCCESS, QUARANTINE_OPEN_TOGGLE, ONE_INFO_TABLE_UPDATE } from '../constants'
const initialState = {
  info: '',
  loading: false,
  error: null,
  show: false,
  quarantines: [],
  quarantinesModal: false
}

export function finfo(state = initialState, action) {
  switch (action.type) {
    case QUARANTINES_MODAL_TOGGLE:
      return {
        ...state,
        quarantinesModal: !state.quarantinesModal
      }
    case ONE_INFO_TABLE_UPDATE:
        return {
          ...state,
          info:  { ...state.info, [action.payload.identifier]: action.payload.value}
        }
    case QUARANTINE_OPEN_TOGGLE:
      return {
        ...state,
        quarantines: state.quarantines.map((item, index) => {
          return (action.payload.index === index) ? { ...item, open: !item.open } : { ...item, open: false }
        })
      }
    case ADD_CHECK_BEGIN:
      return {
        ...state,
        loading: true
      }
    case ADD_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        quarantines: [action.payload.came,...state.quarantines]
      }
    case ONE_INFO_CLICKED:
      return {
        ...state,
        loading: true,
        error: null,
        show: false
      }
    case ONE_INFO_CLICKED_SUCCESS:
      return {
        ...state,
        loading: false,
        show: true,
        info: action.payload.field,
        quarantines: action.payload.quarantines
      }
    case ONE_INFO_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        info: action.payload.field,
        quarantines: action.payload.quarantines
      }
    case ONE_INFO_CLICKED_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        info: null,
        show: false,
        quarantines: null
      }
    case ONE_INFO_CLOSED: {
      return {
        ...state,
        loading: false,
        error: null,
        show: false,
        quarantines: null
      }
    }
    default: return state;
  }
}

import { combineReducers } from "redux";
import { auth } from '../reducers/auth.reducer';
import { registration } from '../reducers/registration.reducer';
import { users } from '../reducers/users.reducer';
import { alert } from '../reducers/alert.reducer';
import { fields } from '../reducers/field.reducer';
import { fieldsTable } from '../reducers/field_table.reducer';
import { finfo } from '../reducers/finfo.reducer';
import { cities } from '../reducers/city.reducer';
import { seeds } from '../reducers/seed.reducer';
import { pests } from '../reducers/pest.reducer';
import { statistics } from '../reducers/statistics.reducer';
import { allData } from '../reducers/alldata.reducer'
import { googleFields } from '../reducers/googlefields.reducer'
import { medicines } from '../reducers/medicine.reducer'
import orders from "../views/Roles/reducer";
import profields from "../views/Map/reducer";
import checks from "../views/QuarantineChecks/reducer";
import lab_checks from "../views/Laboratory/reducer";
import quar_stats from "../views/Statistics/reducer";
import chats from "../views/Chat/reducer";
import calls from "../views/Calls/reducer";
export default combineReducers({
    chats,
    auth,
    registration,
    fields,
    googleFields,
    finfo,
    alert,
    cities,
    seeds,
    pests,
    fieldsTable,
    statistics,
    allData,
    users,
    medicines,
    orders,
    profields,
    checks,
    lab_checks,
    quar_stats,
    calls
})
import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest, { fileRequest } from "../../helpers/createRequest";

import * as routines from "./routines";
function* UpdateWatcher({ payload }) {
  const { id, values, curType, onSuccess = () => { }, onError = () => { } } = payload;

  try {
    yield put(routines.update.request());

    const response = yield call(createRequest.post, `update/call/${id}`, { ...values });
    const lab_checks = yield select(state => state.lab_checks.all);
    const perms = yield select(state => state.auth.permissions);

    let newResults
    if (lab_checks.results && response.data.laboratory_status != curType) {
      newResults = lab_checks.results.filter(item => item.id !== response.data.id)
      yield put(routines.loadAll.success({ results: newResults, meta: lab_checks.meta }));
    }
    yield put(routines.update.success(response.data));
    yield call(onSuccess);
  } catch (error) {
    yield put(routines.update.failure());
    yield call(onError, error);
  } finally {
    yield put(routines.update.fulfill());
  }
}

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "get/calls", {
      params: {
        page: payload.page,
        page_size: 20,
        ...payload
      }
    });

    const { links,
      page_size,
      count,
      total_pages, results } = response.data;

    yield put(routines.loadAll.success({
      results,
      meta: {
        links,
        page_size,
        count,
        total_pages
      }
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}


function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/laboratory/controls/${payload.id}/`);

    const content = response.data;

    yield put(routines.loadOne.success({ results: content }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
    takeLatest(routines.update.TRIGGER, UpdateWatcher),
  ]);
}

import get from "lodash/get";
import findIndex from "lodash/findIndex";

export default (action = {}, actionName, reducerName = "all") => {
  return {
    [action.SUCCESS](state, { payload }) {
      return {
        ...state,
        [reducerName]: {
          ...state[reducerName],
          results: setData(get(state, [reducerName, "results"]) || [], actionName, payload)
        }
      };
    }
  };
};

function setData(data, actionName, payload) {
  let newData = [...data];

  if (actionName == "create") {
    newData = [...newData, payload];
  } else if (actionName == "update") {
    const updatedItemId = findIndex(data, i => i.id == payload.id);
    newData[updatedItemId] = payload;
  }

  return newData;
}

export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',
    USERS_ADD_SUCCESS:'USERS_ADD_SUCCESS',
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    DELETE_USER: 'DELETE_USER',
    USERS_LOGOUT: 'USERS_LOGOUT',

    USERS_GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    USERS_GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    USERS_GETALL_FAILURE: 'USERS_GETALL_FAILURE',
    SET_CURRENT_USER_PAGE:'SET_CURRENT_USER_PAGE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'  
}
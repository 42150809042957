import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk" 
export const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk,sagaMiddleware];

// if (process.env.NODE_ENV === "development") {
//   const { createLogger } = require("redux-logger");
//   const loggerMiddleware = createLogger();

//   middlewares.push(loggerMiddleware);
// }

export default middlewares;

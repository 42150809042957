import createReducerFactory from "../../helpers/createReducerFactory";
import createReducerState from '../../helpers/createReducerState';
import createFormReducer from '../../helpers/createFormReducer';
import defaultState from '../../constants/defaultState';

import { loadAll } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  // create: defaultState,
  update: defaultState

};

const factories = {
  ...createReducerState(loadAll, "all"),
};

export default createReducerFactory(initialState, factories);

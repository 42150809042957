import { createRoutine } from "redux-saga-routines";

export const create = createRoutine("CHATS/CREATE");
export const all = createRoutine("CHATS/ALL");
export const loadmore = createRoutine("CHATS/LOADMORE");
export const one = createRoutine("CHATS/ONE");
export const current = createRoutine("CHATS/CURRENT");
export const update = createRoutine("CHATS/UPDATE");
export const chats = createRoutine("CHATS/ALL");
export const messages = createRoutine("MESSAGES/ALL");
export const create_message = createRoutine("MESSAGES/CREATE");
export const forward_message = createRoutine("MESSAGES/FORWARD_MESSAGE");
export const create_chat = createRoutine("MESSAGES/CREATE_CHAT");
export const create_local_message = createRoutine("MESSAGES/CREATE_LOCAL_MESSAGE");




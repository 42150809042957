export const userService = {
  getUserRole,
  isAndijan,
  userPrivacy
};
function getUserRole(){
  const user=localStorage.getItem('personnel')
  if(user){
      return JSON.parse(user).role?JSON.parse(user).role:localStorage.removeItem('personnel')
  }else{
    return 404
  }
}
function isAndijan(){
  const user=localStorage.getItem('personnel')
  if(user){
      var us=JSON.parse(user).point_id
      return (us===2)?true:false
  }else{
    return false
  }
}
function userPrivacy(){
  const tok=localStorage.getItem('personnel')
  if(tok){
    return JSON.parse(tok).token;
  }else{
    return ""
  }
}
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';

import * as serviceWorker from './serviceWorker';
import store from './store/store.js'
import {subscribe} from './helpers/createRequest';
import App from './App';

const allState = store()
subscribe(allState);
ReactDOM.render(
  <Provider store={allState}>
    <App/>
  </Provider>,
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

import { seedConstants } from '../constants'

const initialState = {
  seeds: [],
  indexed:[],
  loading: false,
  error: null
}

export function seeds(state = initialState, action) {
  switch (action.type) {
    case seedConstants.FETCH_ALL_SEEDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case seedConstants.FETCH_ALL_SEEDS_SUCCESS:
      return {
        ...state,
        loading: false,
        seeds: action.payload.seeds,
        indexed: action.payload.indexed
      }
    case seedConstants.FETCH_ALL_SEEDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        seeds: [],
      }
    default: return state;
  }
}

import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { fStatuses } from "../../helpers";
import _ from 'lodash';
import createRequest from "../../helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "get/deed/list", {
      params: {
        ...payload,
        page_size: 20
      }
    });

    const { results, total_pages, page_size, count, links } = response.data;
    yield put(routines.loadAll.success({
      results: results,
      meta: {
        total_pages, page_size, count, links
      }
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
  ]);
}

import React from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {PermissionsProvider, AuthorizedRoute} from '@tshio/react-router-permissions';
import './App.scss';
import './index.css';
import history from './helpers/history'
import {useSelector} from 'react-redux';
import {PreloaderWithoutState} from './components/UI/Preloader';
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const Page404 = React.lazy(() => import('./views/Pages/Page404/Page404'));
const ProtectedAreas = React.lazy(() => import('./views/Map/ProtectedAreas'));
const Page500 = React.lazy(() => import('./views/Pages/Page500/Page500'));
const FrontLayout = React.lazy(() => import('./containers/FrontLayout/DefaultLayout'))
const AdminLayout = React.lazy(() => import('./containers/AdminLayout/DefaultLayout'))
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
const SignIn = React.lazy(() => import('./views/Pages/Login/SignIn'));
const Check = React.lazy(() => import('./views/Check/Check'));
const DeedSocial = React.lazy(() => import('./views/Deed/SocialDeed'));
const DeedFarming = React.lazy(() => import('./views/Deed/FarmingDeed'));
const DeedHousehold = React.lazy(() => import('./views/Deed/HouseholdDeed'));
const CheckRussian = React.lazy(() => import('./views/Check/CheckRussian'));
const LabCheck = React.lazy(() => import('./views/LabCheck/LabCheck'));

// it's possible to override strategy for single route


const App = () => {
  const {permissions} = useSelector(state => ({
    permissions: state.auth.permissions
  }))
  const authorizationStrategy = (currentRoles, requirement) => {
    return currentRoles.filter(e => requirement.includes(e)).length > 0
    // currentRoles.indexOf(requirement) != -1 ? true : false
  }
  const loginAuthorizationStrategy = (currentRoles, requirement) => (currentRoles && currentRoles.length)
  const loading = () => <PreloaderWithoutState/>
  const perms = ['dalaApp.can_add_region_field', 'dalaApp.can_see_region_field', 'dalaApp.delete_field', 'dalaApp.change_field', 'laboratory.add_labcontrol', 'laboratory.change_labcontrol', 'laboratory.view_labcontrol', 'laboratory.delete_labcontrol', 'laboratory.register_labcontrol', 'laboratory.check_labcontrol']
  return (
    <QueryClientProvider client={queryClient}>
      <PermissionsProvider permissions={permissions} authorizationStrategy={loginAuthorizationStrategy}>
        <Router history={history}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <AuthorizedRoute path="/login" requires={perms} authorizationStrategy={authorizationStrategy}>
                {(props) => {
                  // console.log(props)
                  return props.isAuthorized ? <Redirect to="/user"/> : <Login  {...props} />
                }}
              </AuthorizedRoute>
              <AuthorizedRoute path="/auth" requires={perms} authorizationStrategy={authorizationStrategy}>
                {(props) => {
                  return props.isAuthorized ? <Redirect to="/user"/> : <SignIn  {...props} />
                }}
              </AuthorizedRoute>
              <Route path="/deed/social/:deed_id?" name="DeedSocial" render={props => <DeedSocial {...props} />}/>
              <Route path="/deed/farming/:deed_id?" name="DeedFarming" render={props => <DeedFarming {...props} />}/>
              <Route path="/deed/household/:deed_id?" name="DeedHousehold"
                     render={props => <DeedHousehold {...props} />}/>
              <Route path="/check/:check_id?" name="Check" render={props => <Check {...props} />}/>
              {/*<Route path="/ru/check/:check_id?" name="CheckRussian" render={props => <CheckRussian {...props} />}/>*/}
              <Route path="/lab-check/:check_id" name="Laboratory Check" render={props => <LabCheck {...props} />}/>
              <Route path="/404" name="Page 404" render={props => <Page404 {...props} />}/>
              <Route path="/500" name="Page 500" render={props => <Page500 {...props} />}/>
              <Route path="/areas" name="AreasProtected" render={() => <ProtectedAreas/>}/>
              <AuthorizedRoute path="/user" requires={perms} authorizationStrategy={authorizationStrategy}>
                {(props) => {
                  return props.isAuthorized ? <AdminLayout {...props} /> : <Redirect to="/login" {...props} />
                }}
              </AuthorizedRoute>
              <Route path="/" name="Default Page" render={props => <FrontLayout {...props} />}/>
            </Switch>
          </React.Suspense>
        </Router>
      </PermissionsProvider>
    </QueryClientProvider>
  );
};
export default App;

import { all, fork } from "redux-saga/effects";
import ordersSagas from "../views/Roles/sagas";
import profieldsSagas from "../views/Map/sagas";
import checksSagas from "../views/QuarantineChecks/sagas";
import labSagas from "../views/Laboratory/sagas";
import quarStatsSagas from "../views/Statistics/sagas";
import chatSagas from "../views/Chat/sagas";
import callSagas from "../views/Calls/sagas";
export default function* rootSaga(getState) {
    yield all([
        fork(ordersSagas),
        fork(profieldsSagas),
        fork(checksSagas),
        fork(labSagas),
        fork(quarStatsSagas),
        fork(chatSagas),
        fork(callSagas)
    ])
};
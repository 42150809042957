import { RESET_FILTER, FETCH_ALL_FIELDS_BEGIN, FETCH_ALL_FIELDS_SUCCESS, FETCH_ALL_FIELDS_ERROR, ADD_FIELD, ADD_FIELD_SUCCESS, FILTER_FIELDS, CHANGED_GOOGLE_FIELDS } from '../constants'
import { findCommonElement } from '../helpers'
const initialState = {
  fields: [],
  googleFields: [],
  loading: false,
  filteredFields: [],
  error: null
}

export function fields(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_FIELDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case ADD_FIELD:
      return {
        ...state,
        loading: true
      }
    case FILTER_FIELDS:
      return {
        ...state,
        filteredFields: state.fields.filter(item => {
          return findCommonElement(action.payload.items, item.seeds)
        })
      }
    case RESET_FILTER:
      return {
        ...state,
        filteredFields: []
      }
    case ADD_FIELD_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: [...state.fields, action.payload.field]
      }
    case FETCH_ALL_FIELDS_SUCCESS:
      return {
        ...state,
        loading: false,
        fields: [...action.payload.fields],
        googleFields: [...action.payload.googleFields]
      }
    case FETCH_ALL_FIELDS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fields: [],
        googleFields: []
      }
      case CHANGED_GOOGLE_FIELDS:
          return {
            ...state,
            googleFields: [...action.payload.fields]
          }
    default: return state;
  }
}


import _ from "lodash";
export function findCommonElement(array1, array2) {
  // Loop for array1
  for (let i = 0; i < array1.length; i++) {
    // Loop for array2
    for (let j = 0; j < array2.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (array1[i] === array2[j]) {
        // Return if common element found
        return true;
      }
    }
  }

  // Return if no common element exist
  return false;
}
export const fStatuses = (s) => {
  switch (parseInt(s)) {
    case 1:
      return "#F2B705";
      break;
    case 2:
      return "#43A047";
      break;
    case 3:
      return "#D90000";
      break;
    default:
      return "#000";
  }
};
export const getFieldStatusText = (item) => {
  switch (item) {
    case 1:
      return "Tekshirilmagan";
      break;
    case 2:
      return "Yaxshi";
      break;
    default:
      return "Zararlangan";
  }
};
export function cutReversedFromChar(given, specific) {
  if (given.indexOf(specific) !== -1) {
    return given.substring(given.indexOf(specific) + 1, given.length);
  } else {
    return given.substring(given.indexOf("–") + 1, given.length);
  }
}
export function generateRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
export const getCityName = (c_id, given_cities) => {
  if (given_cities.length > 0 && c_id) {
    return _.get(_.find(given_cities, ["id", c_id]), "name_uz","");
  }
};
export const getDistrictName = (c_id, d_id, given_districts) => {
  if (c_id && d_id && given_districts.length > 0) {
    const foundDistricts = _.find(given_districts, ["id", c_id]).districts;
    return _.find(foundDistricts, ["id", d_id]).name_uz;
  }
};
export const arraysEqual = (_arr1, _arr2) => {
  if (
    !Array.isArray(_arr1) ||
    !Array.isArray(_arr2) ||
    _arr1.length !== _arr2.length
  ) {
    return false;
  }

  // .concat() is used so the original arrays are unaffected
  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

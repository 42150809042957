export function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}
export function authHeader() {
  // return authorization header with jwt token
  const userString = localStorage.getItem("personnel")
  let user
  if (userString) {
    user = JSON.parse(userString)
  } else {
    user = false
  }
  const csrftoken = getCookie('csrftoken');
  if (user) {
    return {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken,
      "Authorization": "Token " + user.token
    };
  } else {
    return {};
  }
}
export function getRole() {
  let loggedInUser = localStorage.getItem("personnel");
  if (loggedInUser) {
    return JSON.parse(loggedInUser).role
  }
  return 0
}
export function isAuthorized() {
  let loggedInUser = localStorage.getItem("personnel");
  return !!loggedInUser;

}

import { takeLatest, all, call, put, select } from "redux-saga/effects";
import { fStatuses } from "../../helpers";
import _ from 'lodash';
import createRequest from "../../helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());

    const response = yield call(createRequest.get, "/get/fields");

    const { data } = response;
    const normalized = yield call(normalizer, data);
    yield put(routines.loadAll.success({
      content: normalized
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}
const normalizer = (res) => {
  let forGoogle = []
  for (let index = 0; index < res.length; index++) {
    let item = res[index];
    if (item.location.length > 0 && Array.isArray(item.location)) {
      forGoogle.push({
        ...item,
        location: item.location.map((litem, i, arr) => {
          if (arr.length - 1 === i) {
            if (_.has(arr[0], 'long')) {
              return {
                lat: parseFloat(arr[0].lat),
                lng: parseFloat(arr[0].long)
              }
            } else {
              return {
                lat: parseFloat(arr[0].lat),
                lng: parseFloat(arr[0].lng)
              }
            }
          } else {
            if (_.has(arr[i], 'long')) {
              return {
                lat: parseFloat(arr[i].lat),
                lng: parseFloat(arr[i].long)
              }
            } else {
              return {
                lat: parseFloat(arr[i].lat),
                lng: parseFloat(arr[i].lng)
              }
            }
          }
        }),
        options: {
          fillColor: fStatuses(item.field_color),
          fillOpacity: 0.5,
          strokeColor: "#000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          clickable: true,
          draggable: false,
          editable: false,
          geodesic: false,
          zIndex: 10,
        },
        visible: true
      })
    }
  }
  return forGoogle
}

export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
  ]);
}

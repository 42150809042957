import {pestConstants} from '../constants'

const initialState = {
  pests: [],
  loading: false,
  error: null,
  current: 1,
  one: ''
}

export function pests(state = initialState, action) {
  switch (action.type) {
    case pestConstants.FETCH_ALL_PESTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case pestConstants.FETCH_ONE_PEST_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      }
    case pestConstants.FETCH_ONE_PEST_SUCCESS:
      return {
        ...state,
        loading: false,
        one: action.payload.pest
      }
    case pestConstants.SET_CURRENT_PAGE:
      return {
        ...state,
        current: action.payload.page
      }
    case pestConstants.TOGGLE_PEST:
      return {
        ...state,
        loading: false,
        pests: state.pests.map((item, index) => (
          index === action.payload.pest_id ? {...item, isopen: !item.isopen} : {...item, isopen: false}
        ))
      }
    case pestConstants.FETCH_ALL_PESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        pests: action.payload.pests
      }
    case pestConstants.FETCH_ALL_PESTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        pests: []
      }
    default:
      return state;
  }
}

import { takeLatest, all, call, put, select } from "redux-saga/effects";
// select
import axios from "axios";
import * as routines from "./routines";
import _ from "lodash"
import { BASE_URL } from "../../constants/assalam_variables";
function* GetOfferOne({ payload }) {
  const { _id, values } = payload
  try {
    yield put(routines.one.request());

    const response = yield call(axios.post, `${BASE_URL}offer/get-by-id`, { _id }, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {

        yield put(routines.one.success({
          results: data.data
        }));
      } else {
        yield put(routines.one.failure(data.message));
      }
    }

  } catch (error) {
    yield put(routines.one.failure(error));
  } finally {
    yield put(routines.one.fulfill());
  }
}
function* UpdateOffer({ payload }) {
  const { values, onSuccess, onError } = payload
  try {
    yield put(routines.update.request());

    const response = yield call(axios.post, `${BASE_URL}offer/update`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        yield put(routines.update.success(data.data));
        yield call(onSuccess);
      } else {
        yield put(routines.update.failure(data.message));
        yield call(onError);
      }
    }

  } catch (error) {
    yield put(routines.update.failure(error));
    yield call(onError);
  } finally {
    yield put(routines.update.fulfill());
  }
}
function* CreateOffer({ payload }) {
  const { values, onSuccess, onError } = payload
  try {
    yield put(routines.create.request());

    const response = yield call(axios.post, `${BASE_URL}bid/create`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        yield put(routines.create.success({ results: data.data }));
        yield call(onSuccess, "Success")
      } else {
        yield put(routines.create.failure());
        yield call(onError, "Error")
      }
    }

  } catch (error) {
    yield put(routines.create.failure());
    yield call(onError, "Error")
  } finally {
    yield put(routines.create.fulfill());
  }

}
function* GetAllMessages({ payload }) {
  const { values } = payload
  try {
    yield put(routines.messages.request());

    const response = yield call(axios.post, `${BASE_URL}messages/get`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        yield put(routines.messages.success({ results: data.data.data, meta: { total: data.data.total } }));
      } else {
        yield put(routines.messages.failure());
      }
    }

  } catch (error) {
    yield put(routines.messages.failure({ error }));
  } finally {
    yield put(routines.messages.fulfill());
  }
}
function* CreateLocalMessage({ payload }) {
  const { values } = payload
  const messages = yield select(state => state.chats.messages);
  const { total } = messages.meta;
  try {
    yield put(routines.create_local_message.request());
    yield put(routines.create_local_message.success({ values }));
    yield put(routines.messages.success({ results: [values, ...messages.results], meta: { total: total + 1 } }));
  } catch (error) {
    yield put(routines.create_local_message.failure());
  } finally {
    yield put(routines.create_local_message.fulfill());
  }

}
function* CreateMessage({ payload }) {
  const { values, onSuccess, onError } = payload
  const messages = yield select(state => state.chats.messages);
  const { total } = messages.meta;
  try {
    yield put(routines.create_message.request());

    const response = yield call(axios.post, `${BASE_URL}message/send`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        yield put(routines.create_message.success({ results: data.data }));
        yield put(routines.messages.success({ results: [data.data, ...messages.results], meta: { total: total + 1 } }));
        yield call(onSuccess)
      } else {
        yield put(routines.create_message.failure());
        yield call(onError, "Error")
      }
    }

  } catch (error) {
    yield put(routines.create_message.failure());
    yield call(onError, "Error")
    yield call(console.log, error)
  } finally {
    yield put(routines.create_message.fulfill());
  }

}
function* GetAllChats({ payload }) {
  const { values, onNotLeft } = payload

  try {
    yield put(routines.chats.request());

    const response = yield call(axios.post, `${BASE_URL}messages/chat/get`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    const { results: oldChats } = yield select(state => state.chats.chats);
    if (data) {
      if (data.code === 0) {
        if (oldChats) {
          yield put(routines.chats.success({ results: [...oldChats, ...data.data.data], meta: { total: data.data.total } }));
        } else {
          yield put(routines.chats.success({ results: [...data.data.data], meta: { total: data.data.total } }));

        }
      } else {
        yield put(routines.chats.failure());
      }
    }

  } catch (error) {
    yield put(routines.chats.failure({ error }));
  } finally {
    yield put(routines.chats.fulfill());
  }
}
function* LoadMoreMessages({ payload }) {
  const { values, onNotLeft } = payload
  const messages = yield select(state => state.chats.messages);
  try {
    yield put(routines.loadmore.request());

    const response = yield call(axios.post, `${BASE_URL}messages/get`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        if (data.data.data.length) {
          yield put(routines.messages.success({ results: [...messages.results, ...data.data.data], total: data.data.total }));
        }
      } else {
        yield put(routines.loadmore.failure());
      }
    }

  } catch (error) {
    yield put(routines.loadmore.failure({ error }));
  } finally {
    yield put(routines.loadmore.fulfill());
  }
}
function* CreateChat({ payload }) {
  const { values } = payload

  const chats = yield select(state => state.chats.chats);
  const exist = chats.results.find(item => { return values._id == item._id })
  if (exist)
    // if (exist) {
    yield put(routines.chats.success({ results: [values, ...chats.results], meta: { total: parseInt(chats.meta.total) + 1 } }));
  // }
}
function* SetTargetSelected({ payload }) {
  const { values } = payload
  yield put(routines.current.success({ results: { ...values } }));
}
function* ForwardMessage({ payload }) {
  const { values, onSuccess, onError } = payload
  try {
    yield put(routines.forward_message.request());

    const response = yield call(axios.post, `${BASE_URL}message/forward`, values, {
      headers: {
        Authorization: ["Bearer", values.accessToken].join(" ")
      }
    });

    const { data } = response;
    if (data) {
      if (data.code === 0) {
        yield put(routines.forward_message.success({ results: data.data }));
        yield call(onSuccess)
      } else {
        yield put(routines.forward_message.failure());
      }
    }

  } catch (error) {
    yield put(routines.create_message.failure());
    yield call(onError, "Error")
    yield call(console.log, error)
  } finally {
    yield put(routines.create_message.fulfill());
  }
}
export default function* () {
  yield all([
    takeLatest(routines.create.TRIGGER, CreateOffer),
    takeLatest(routines.create_local_message.TRIGGER, CreateLocalMessage),
    takeLatest(routines.create_message.TRIGGER, CreateMessage),
    takeLatest(routines.forward_message.TRIGGER, ForwardMessage),
    takeLatest(routines.create_chat.TRIGGER, CreateChat),
    // takeLatest(routines.all.TRIGGER, AllOffers),
    takeLatest(routines.one.TRIGGER, GetOfferOne),
    takeLatest(routines.current.TRIGGER, SetTargetSelected),
    takeLatest(routines.update.TRIGGER, UpdateOffer),
    takeLatest(routines.chats.TRIGGER, GetAllChats),
    takeLatest(routines.messages.TRIGGER, GetAllMessages),
    takeLatest(routines.loadmore.TRIGGER, LoadMoreMessages),
  ]);
}

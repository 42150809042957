import { userConstants } from '../constants'

const initialState = {
  users: [],
  current: 1,
  total: 0,
  count: 0,
  loading: false,
  error: null
}

export function users(state = initialState, action) {
  switch (action.type) {
    case userConstants.USERS_GETALL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null
      }
    case userConstants.USERS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [action.payload.user, ...state.users]
      }
    case userConstants.USERS_GETALL_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
        count: action.payload.count,
        total: action.payload.total,
      }
    case userConstants.USERS_GETALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        users: []
      }
    case userConstants.SET_CURRENT_USER_PAGE:
      return {
        ...state,
        current: action.payload.n

      }
    case userConstants.DELETE_USER:
      var array = [...state.users]; // make a separate copy of the array
      var index = array.findIndex(item=>item.id==action.payload.n)
      console.log(action.payload.n)
      if (index !== -1) {
        array.splice(index, 1);
        return {
          ...state,
          users: [...array]
        }
      }else{console.log('not found')}
    default: return state;
  }
}

import { takeLatest, all, call, put, select } from "redux-saga/effects";

import createRequest from "../../helpers/createRequest";

import * as routines from "./routines";

function* LoadAllWatcher({ payload }) {
  try {
    yield put(routines.loadAll.request());
    const cities = yield select(state => state.cities.cities);
    const stats = yield all(
      cities.map(({ id }) => {
        return call(createRequest.get, `get/quarantines/statistics/${id}`, { params: {...payload } })
      })
    )
    yield put(routines.loadAll.success({
      results: stats.map(item => (item.data))
    }));
  } catch (error) {
    yield put(routines.loadAll.failure());
  } finally {
    yield put(routines.loadAll.fulfill());
  }
}

function* LoadOneWatcher({ payload }) {
  try {
    yield put(routines.loadOne.request());

    const response = yield call(createRequest.get, `/auth/users/${payload.user_id}`);

    const content = response.data;

    yield put(routines.loadOne.success({ content }));
  } catch (error) {
    yield put(routines.loadOne.failure());
  } finally {
    yield put(routines.loadOne.fulfill());
  }
}
export default function* () {
  yield all([
    takeLatest(routines.loadAll.TRIGGER, LoadAllWatcher),
    takeLatest(routines.loadOne.TRIGGER, LoadOneWatcher),
  ]);
}

import { userConstants } from '../constants';
import {get} from 'lodash'

let loggedInUser = JSON.parse(localStorage.getItem("personnel"));
const initialState = loggedInUser ?
  {
    user: loggedInUser,
    loggedIn: true,
    error: false,
    loading: false,
    permissions: loggedInUser.permissions?loggedInUser.permissions:[],
    token: loggedInUser.token?loggedInUser.token:""
  } :
  {
    user: '',
    loggedIn: false,
    error: false,
    loading: false,
    permissions: [],
    token:""
  };

export function auth(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        error: false,
        loggedIn: true,
        loading: false,
        user: action.payload.user,
        permissions: get(action.payload.user, 'permissions', []),
        token: get(action.payload.user, 'token', "")
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        error: true,
        loggedIn: false,
        loading: false
      };
    case userConstants.USERS_LOGOUT:
      return {
        ...state,
        error: false,
        loggedIn: false,
        loading: false,
        user: "",
        token:"",
        permissions: []
      };
    default:
      return state
  }
}

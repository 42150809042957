import { FETCH_ALL_DATA_BEGIN, FETCH_ALL_DATA_SUCCESS, FETCH_ALL_DATA_ERROR } from '../constants'

const initialState = {
  fields: [],
  count: 0,
  regionalCount: {},
  loading: false,
  error: null
}

export function allData(state = initialState, action) {
  switch (action.type) {
    case FETCH_ALL_DATA_BEGIN:
      return {
        ...state,
        loading: true
      }
    case FETCH_ALL_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        fields: action.payload.fields,
        regionalCount: action.payload.regionalCount,
        count: action.payload.length
      }
    case FETCH_ALL_DATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      }
    default: return state;
  }
}

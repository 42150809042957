import createReducerFactory from "../../helpers/createReducerFactory";
import createReducerState from '../../helpers/createReducerState';
import defaultState from '../../constants/defaultState';
import createFormReducer from '../../helpers/createFormReducer';

import { all, chats, messages,create, one,update, create_message, current } from "./routines";

const initialState = {
  create: defaultState,
  all: defaultState,
  one: defaultState,
  current:defaultState,
  update: defaultState, 
  chats: defaultState,
  messages: defaultState,
  create_message: defaultState
};

const factories = {
  ...createReducerState(create, "create"),
  ...createReducerState(all, "all"),
  ...createReducerState(one, "one"),
  ...createReducerState(current, "current"),
  ...createFormReducer(update,"update"),
  ...createReducerState(chats,"chats"),
  ...createReducerState(messages, "messages"),
  ...createReducerState(create_message, "create_message"),
  
  // ...createReducerState(verificate, "verifier"),
  // ...createReducerState(login, "login"),
};

export default createReducerFactory(initialState, factories);

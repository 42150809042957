import createReducerFactory from "../../helpers/createReducerFactory";
import createReducerState from '../../helpers/createReducerState';
import createFormReducer from '../../helpers/createFormReducer';
import defaultState from '../../constants/defaultState';

import { loadAll, loadOne, update } from "./routines";

const initialState = {
  all: defaultState,
  one: defaultState,
  update: defaultState
};

const factories = {
  ...createReducerState(loadAll, "all"),
  ...createReducerState(loadOne, "one"),
  ...createFormReducer(update, "update")
};

export default createReducerFactory(initialState, factories);
